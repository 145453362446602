<template>
  <div>
    <h2 class="mb-4 mt-2">Stagings</h2>
    <div>
      <!-- Add / edit staging modal -->
      <b-modal
        id="stagingsModal"
        :title="isEdit ? 'Edit staging' : 'New staging'"
        size="lg"
        @ok="stagingFormSubmit"
      >
        <b-form :validated="modalFormValidated">
          <b-form-group
            v-for="(field, index) in modalFields"
            :key="field + index"
            :label="field"
            :label-for="field + index"
            label-cols="4"
          >
            <b-dropdown v-if="field === 'type'" :text="form[field]">
              <b-dropdown-item
                v-for="type in typesDropdown"
                :key="type"
                :active="form[field] === type"
                @click="form[field] = type"
              >
                {{ type }}
              </b-dropdown-item>
            </b-dropdown>
            <b-checkbox v-else-if="field === 'enableS3'" v-model="form[field]" :disabled="isEdit" />
            <b-dropdown
              v-else-if="field === 'branch' && branches[form.type]?.length"
              class="branches"
              :text="form[field]"
            >
              <b-dropdown-item
                v-for="branch in branches[form.type]"
                :key="branch"
                :active="form[field] === branch"
                @click="form[field] = branch"
              >
                {{ branch }}
              </b-dropdown-item>
            </b-dropdown>
            <template v-else>
              <template v-if="datasets[field]">
                <b-form-input
                  type="text"
                  v-model="form[field]"
                  :list="field + index"
                  :placeholder="`Enter ${field}`"
                  :required="true"
                />
                <datalist :id="field + index">
                  <option v-for="(url, index) in datasets[field]" :key="url + index">
                    {{ url }}
                  </option>
                </datalist>
              </template>
              <b-form-input
                v-else
                type="text"
                v-model="form[field]"
                :id="field + index"
                :placeholder="`Enter ${field}`"
                :required="true"
              />
            </template>
          </b-form-group>
        </b-form>
      </b-modal>

      <!-- Stagings table -->
      <p>
        Available stagings: SSR - {{ ssrAvailable }} | API - {{ apiAvailable }} | Admin -
        {{ adminAvailable }}
      </p>
      <b-table
        show-empty
        small
        hover
        stacked="md"
        :busy="loading"
        :items="items"
        :fields="fields"
        ref="stagingsTable"
      >
        <template v-slot:table-busy>
          <div class="loading">Loading...</div>
        </template>

        <template v-slot:cell(url)="data">
          <b-button
            :id="getClipboardButtonId(data.item)"
            class="button-copy-to-clipboard"
            size="sm"
            variant="link"
            v-b-tooltip.click="'Copied'"
            @click="copyUrlToClipboard(data.item)"
          >
            <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 2.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9.45 2a2.5 2.5 0 0 0-4.9 0H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h2v-1.5H3.5v-9h1V5h5V3.5h1V7H12V3a1 1 0 0 0-1-1H9.45zM7.5 9.5h1.25a.75.75 0 0 0 0-1.5h-1.5C6.56 8 6 8.56 6 9.25v1.5a.75.75 0 0 0 1.5 0V9.5zm1.25 5H7.5v-1.25a.75.75 0 0 0-1.5 0v1.5c0 .69.56 1.25 1.25 1.25h1.5a.75.75 0 0 0 0-1.5zm3.75-5h-1.25a.75.75 0 0 1 0-1.5h1.5c.69 0 1.25.56 1.25 1.25v1.5a.75.75 0 0 1-1.5 0V9.5zm-1.25 5h1.25v-1.25a.75.75 0 0 1 1.5 0v1.5c0 .69-.56 1.25-1.25 1.25h-1.5a.75.75 0 0 1 0-1.5z"
              />
            </svg>
          </b-button>
          <a :href="getUrl(data.item)" target="_blank">
            {{ data.item.name }}
          </a>
        </template>

        <template v-slot:cell(branch)="data">
          <a
            :href="`https://gitlab.lfstrm.tv/web/sequoia/sequoia-site/-/tree/${data.item.branch}`"
            target="_blank"
          >
            {{ data.item.branch }}
          </a>
        </template>

        <template v-slot:cell(status)="data">
          <div class="d-flex">
            <div>
              {{ data.item.ready ? 'ready' : 'building' }}
              {{ data.item.buildProgress }}
            </div>
            <div class="dot ml-1 mt-2" :class="getDotColorClass(data.item.status)" />
          </div>
        </template>

        <template v-slot:cell(actions)="data">
          <b-button
            size="sm"
            variant="info"
            class="m-1 ml-2"
            :disabled="data.item.status !== STAGING_STATUS.OUTDATED"
            @click="refreshStaging(data.item)"
          >
            ↺
          </b-button>
          <b-button
            size="sm"
            class="m-1 ml-2"
            variant="warning"
            @click="raisePopup(true, data.item)"
          >
            ✎
          </b-button>
          <b-button size="sm" variant="danger" class="m-1 ml-2" @click="deleteStaging(data.item)">
            ✕
          </b-button>
        </template>
      </b-table>
      <div class="mb-5">
        <b-button type="submit" variant="primary" @click="raisePopup()">Add</b-button>
      </div>
    </div>
    <h2>Console</h2>
    <Console />
  </div>
</template>

<script>
import Console from '@/components/Console.vue';
import * as GitlabApi from '@/helpers/gitlabApi';
import { ApiError } from '@common/request';
import { STAGING_STATUS } from '@/config/constants';
import * as api from '@/api';

const originalForm = {
  type: 'ssr',
  branch: 'master',
  feBaseUrl: 'https://fe.smotreshka.tv',
  beBaseUrl: 'https://smotreshka.sequoia-api.lfstrm.tv',
  wlAppId: 'smotreshka',
  createdBy: '',
  description: '',
  expiresAt: '',
  name: null,
  enableS3: false,
};

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'Stagings',

  components: { Console },

  data() {
    return {
      ssrAvailable: 0,
      apiAvailable: 0,
      adminAvailable: 0,
      items: [],
      isEdit: false,
      loading: true,
      types: { 'sequoia-site': 'ssr', 'sequoia-api': 'api', 'sequoia-admin': 'admin' },
      projects: {},
      branches: {},
      modalFormValidated: false,
      form: { ...originalForm },
      wsConsoleMessages: [],
      datasets: {
        feBaseUrl: [],
        beBaseUrl: [],
        wlAppId: [],
      },
      STAGING_STATUS,
    };
  },

  computed: {
    typesDropdown() {
      let types = Object.values(this.types);
      if (this.isEdit) {
        return types;
      }
      if (!this.ssrAvailable) {
        types = types.filter((type) => type !== 'ssr');
      }
      if (!this.apiAvailable) {
        types = types.filter((type) => type !== 'api');
      }
      if (!this.adminAvailable) {
        types = types.filter((type) => type !== 'admin');
      }
      return types;
    },

    fields() {
      return [
        {
          key: 'type',
          label: 'Type',
        },
        {
          key: 'url',
          label: 'Url',
        },
        {
          key: 'branch',
          label: 'Git branch',
        },
        {
          key: 'feBaseUrl',
          label: 'FE',
        },
        {
          key: 'beBaseUrl',
          label: 'BE',
        },
        {
          key: 'wlAppId',
          label: 'WL',
        },
        {
          key: 'createdBy',
          label: 'Owner',
        },
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'expiresAt',
          label: 'Expires at',
        },
        {
          key: 'status',
          label: 'Status',
        },
        { key: 'actions', label: '', class: 'actions-class' },
      ];
    },

    modalFields() {
      return Object.keys(this.form).filter((fieldName) => {
        const skipFields = ['id'];
        if (this.form.type === 'admin') {
          skipFields.push('wlAppId');
          skipFields.push('enableS3');
        } else if (this.form.type === 'api') {
          skipFields.push('beBaseUrl');
          skipFields.push('name');
        } else if (this.form.type === 'ssr') {
          skipFields.push('name');
          skipFields.push('enableS3');
        }

        return !skipFields.includes(fieldName);
      });
    },

    isNameFieldVisible() {
      return this.form.type === 'admin' && !this.isEdit;
    },

    nameFieldState() {
      if (!this.form.name) {
        return null;
      }
      return !this.form.name.match(/[^a-z0-9-_]+/g);
    },

    wsConsole() {
      return this.$store.state.ws.console;
    },
  },

  async mounted() {
    const projects = await GitlabApi.fetchProjects();
    this.projects = projects.reduce((acc, cur) => {
      const type = this.types[cur.name];
      acc[type] = cur;
      return acc;
    }, {});
    await this.loadItems(true);

    const { data } = await this.$store.dispatch('getWhitelabels', {
      sortBy: 'name',
      sortDesc: false,
      perPage: 50,
      currentPage: 1,
    });
    for (const i in data.data.rows) {
      const row = data.data.rows[i];
      if (row.isActive) {
        if (row.urlFeProd) {
          this.datasets.feBaseUrl.push(row.urlFeProd);
        }
        if (row.urlFeRC) {
          this.datasets.feBaseUrl.push(row.urlFeRC);
        }
        if (row.urlApiProd) {
          this.datasets.beBaseUrl.push(row.urlApiProd);
        }
        if (row.urlApiRC) {
          this.datasets.beBaseUrl.push(row.urlApiRC);
        }
        if (row.name) {
          this.datasets.wlAppId.push(row.name);
        }
      }
    }
  },

  watch: {
    wsConsole(newMessages) {
      const oldMessages = this.wsConsoleMessages;
      const lastMessages = newMessages.slice(oldMessages.length);
      if (
        lastMessages.find(
          (message) => message.includes('Controller:staging') && message.includes('&gt;&gt;&gt;'),
        )
      ) {
        this.refreshingTable();
      }
      this.wsConsoleMessages = [...newMessages];
    },
  },

  methods: {
    async loadItems(showLoader = false) {
      this.loading = showLoader;
      const response = await api.stagings.getStagings();
      this.ssrAvailable = response.ssr.available;
      this.apiAvailable = response.api.available;
      this.adminAvailable = response.admin.available;
      this.items = this.getPreparedItems(response);
      this.loading = false;
      /* eslint-disable no-console */
      await this.checkOutdated().catch(console.error);

      const branches = await Promise.all(
        Object.values(this.projects).map(({ id }) =>
          GitlabApi.fetchBranches(id).catch(this.handlerError),
        ),
      );
      Object.values(this.projects).forEach((project, index) => {
        const key = this.types[project.name];
        this.branches[key] = branches[index].map((branch) => branch.name);
      });
    },

    async checkOutdated() {
      const response = await api.stagings.getStagingsStatus();
      const statuses = response.reduce((acc, stagingsGroup) => {
        stagingsGroup.status.forEach((item) => {
          acc[item.name] = item.status;
        });
        return acc;
      }, {});
      this.items = this.items.map((staging) => ({
        ...staging,
        status: statuses[staging.name] || STAGING_STATUS.UNKNOWN,
      }));
    },

    getPreparedItems(stagings) {
      return Object.values(stagings).reduce((acc, cur) => {
        acc = [
          ...acc,
          ...cur.items.map((item) => ({ ...item, _rowVariant: !item.ready ? 'warning' : '' })),
        ];
        return acc;
      }, []);
    },

    async refreshStaging(staging) {
      this.$bvModal.msgBoxConfirm(`Refresh staging "${staging.name}" ?`).then(async (value) => {
        if (value) {
          await api.stagings.postStagingRefresh(staging);
          await this.loadItems();
        }
      });
    },

    async deleteStaging(staging) {
      this.$bvModal.msgBoxConfirm(`Delete staging "${staging.name}" ?`).then(async (value) => {
        if (value) {
          await api.stagings.postStagingDelete({
            type: staging.type,
            name: staging.name,
          });
          await this.loadItems();
        }
      });
    },

    raisePopup(isEdit = false, staging = null) {
      this.isEdit = isEdit;
      this.modalFormValidated = false;
      if (staging && isEdit) {
        this.form = Object.keys(originalForm).reduce(
          (acc, current) => ({
            ...acc,
            [current]: current === 'enableS3' ? !!staging[current] : staging[current] || '',
          }),
          {},
        );
      } else {
        this.stagingFormReset();
      }
      this.$bvModal.show('stagingsModal');
    },

    stagingFormReset() {
      this.form = { ...originalForm };
    },

    stagingFormSubmit(event) {
      this.modalFormValidated = true;
      const emptyField = this.modalFields
        .map((field) => this.form[field])
        .find((value) => typeof value !== 'boolean' && !value);
      const isInvalid = this.nameFieldState === false || emptyField !== undefined;
      if (isInvalid) {
        event.preventDefault();
        return false;
      }

      if (this.isEdit) {
        api.stagings.postStagingUpdate(this.form);
      } else {
        api.stagings.postStagingAdd(this.form);
      }
      if (!this.isEdit) {
        this.items.push({
          ...this.form,
          ready: '0',
          _rowVariant: 'warning',
        });
      }
      this.$refs.stagingsTable.refresh();
      this.stagingFormReset();
    },

    handlerError(e) {
      if (e instanceof ApiError) {
        if (!e.status) {
          this.noConnection = true;
        } else {
          this.error = `${e.method} ${e.url} - ${e.message}`;
        }
      } else {
        this.error = e.message;
      }
    },

    async refreshingTable() {
      await this.loadItems();
      this.$refs.stagingsTable.refresh();
    },

    getDotColorClass(status) {
      switch (status) {
        case STAGING_STATUS.ACTUAL:
          return 'green';
        case STAGING_STATUS.OUTDATED:
          return 'blue';
        case STAGING_STATUS.NO_REMOTE_BRANCH:
          return 'red';
        default:
          return 'grey';
      }
    },

    getUrl(item) {
      return `http://${item.name}.${item.type}.test.seq.lfstrm.tv`;
    },

    getClipboardButtonId(item) {
      return `copy_${item.name}`;
    },

    copyUrlToClipboard(item) {
      navigator.clipboard.writeText(this.getUrl(item));
      this.$root.$emit('bv::show::tooltip', this.getClipboardButtonId(item));
      setTimeout(() => {
        this.$root.$emit('bv::hide::tooltip');
      }, 1000);
    },
  },
};
</script>

<style scoped>
.loading {
  text-align: center;
  padding: 20px 0;
}

.branches::v-deep .dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 100px;
}

.dot.blue {
  background-color: #0056c9;
}

.dot.green {
  background-color: #08c500;
}

.dot.red {
  background-color: #f38d8e;
}

.dot.grey {
  background-color: #949494;
}

.button-copy-to-clipboard {
  padding: 0.1rem 0.25rem;
  margin-top: -0.5rem;
}
.button-copy-to-clipboard:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
