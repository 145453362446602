<template>
  <div>
    <h2 class="mb-4 mt-2">Releases</h2>

    <!-- No connection -->
    <template v-if="noConnection">
      <b-alert show variant="danger">
        Check connection to
        <a href="https://gitlab.lfstrm.tv" target="_blank"> https://gitlab.lfstrm.tv </a>
      </b-alert>
      <b-button variant="primary" @click="$router.go(0)">Reload</b-button>
    </template>

    <!-- Project -->
    <b-form-group v-if="projects && currentProject" label="Project" label-cols="4">
      <b-dropdown :text="currentProject.name">
        <b-dropdown-item
          v-for="project in projects"
          :key="project.id"
          @click="selectProject(project)"
        >
          {{ project.name }}
        </b-dropdown-item>
      </b-dropdown>
    </b-form-group>

    <!-- Source branches -->
    <b-form-group
      v-if="sourceBranches && sourceBranches.length"
      label="Source branch"
      label-cols="4"
    >
      <b-dropdown :text="sourceBranch ? sourceBranch.name : ''">
        <b-dropdown-item
          v-for="branch in sourceBranches"
          :key="branch.id"
          @click="selectSourceBranch(branch.name)"
        >
          {{ branch.name }}
        </b-dropdown-item>
      </b-dropdown>
    </b-form-group>

    <!-- Target branches -->
    <b-form-group
      v-if="targetBranches && targetBranches.length"
      label="Target branch"
      label-cols="4"
    >
      <b-dropdown :text="targetBranch ? targetBranch.name : ''">
        <b-dropdown-item
          v-for="branch in targetBranches"
          :key="branch.id"
          @click="selectTargetBranch(branch.name)"
        >
          {{ branch.name }}
        </b-dropdown-item>
      </b-dropdown>
    </b-form-group>

    <!-- Preview release MR -->
    <b-form-group v-if="newRelease" label-cols="4" class="mt-5">
      <h4>{{ newRelease.title }}</h4>
      <div v-html="newRelease.descriptionFormatted" class="mb-2" />
      <div v-if="isReleaseVersionLowerThanMasterVersion" class="text-danger mb-2">
        Warning! Release version = {{ releaseVersion }} is lower then the version in master =
        {{ masterVersion }}
      </div>
      <div v-if="hasMismatchVersionsNumber" class="text-danger mb-2">
        Warning! Version mismatch in package.json: expected
        {{ sourceBranch.name.replace('release/', '') }}, got {{ releaseVersion }}<br />
        Please update version number in package.json
      </div>
      <div v-if="showAlreadyReleasedVersionNote" class="text-danger mb-3">
        Warning! Version {{ releaseVersion }} has already been released!<br />
        Please update version number in package.json
      </div>

      <div v-if="!mergeRequests.length" class="text-danger mb-3">No merged MRs</div>
      <div v-else-if="jiraIssuesWithoutMergeRequest.length" class="mt-5 mb-5">
        <div class="text-danger mb-2">Warning! Some Jira issues don't have merged MRs:</div>
        <div v-for="issue in jiraIssuesWithoutMergeRequest" class="text-danger">
          -
          <a :href="`https://lifestream.atlassian.net/browse/${issue.key}`" target="_blank">{{
            issue.key
          }}</a>
          {{ issue.summary }}
        </div>
      </div>

      <b-button variant="primary" :disabled="disabled" @click="submitMergeRequest()"
        >Submit</b-button
      >
    </b-form-group>

    <b-form-group v-if="this.newRelease && this.newRelease.mergeRequest" label-cols="4">
      New
      <a :href="this.newRelease.mergeRequest.web_url" target="_blank">merge request</a>
      has been created!
    </b-form-group>

    <!-- Loader -->
    <b-form-group v-if="loading" label-cols="4"> Loading... </b-form-group>

    <template v-if="error">
      <b-alert variant="danger" show>
        {{ error }}
      </b-alert>
      <b-button variant="primary" @click="$router.go(0)">Reload</b-button>
    </template>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import * as GitlabApi from '@/helpers/gitlabApi';
import * as JiraApi from '@/helpers/jiraApi';
import { ApiError } from '@common/request';

export default {
  name: 'Releases',

  data() {
    return {
      loading: true,
      projects: [],
      branches: [],
      releases: [],
      currentProject: null,
      sourceBranch: null,
      targetBranch: null,
      masterVersion: '',
      releaseVersion: '',
      mergeRequests: [],
      jiraIssues: [],
      newRelease: null,
      error: '',
      noConnection: false,
    };
  },

  async mounted() {
    this.error = '';
    this.noConnection = false;
    this.projects = (await GitlabApi.fetchProjects().catch(this.handlerError)) || [];
    if (this.projects.length) {
      const project = this.projects.find(({ name }) => name === 'sequoia-site');
      if (project) {
        await this.selectProject(project);
      } else {
        this.error = 'Unable to find "sequoia-site" project in GitLab';
      }
    }
    this.loading = false;
  },

  computed: {
    disabled() {
      return (
        this.hasMismatchVersionsNumber ||
        this.isReleaseVersionLowerThanMasterVersion ||
        !this.mergeRequests.length ||
        !!this.jiraIssuesWithoutMergeRequest.length
      );
    },

    currentProjectId() {
      return this.currentProject ? this.currentProject.id : 0;
    },

    hasMismatchVersionsNumber() {
      return (
        this.releaseVersion.replace('v', '') !== this.sourceBranch.name.replace('release/', '')
      );
    },

    /**
     * @see https://stackoverflow.com/a/65687141/2393499
     * localeCompare returns:
     *  0: version strings are equal
     *  1: version a is greater than b
     * -1: version b is greater than a
     */
    isReleaseVersionLowerThanMasterVersion() {
      return (
        this.releaseVersion.localeCompare(this.masterVersion, undefined, {
          numeric: true,
          sensitivity: 'base',
        }) <= 0
      );
    },

    showAlreadyReleasedVersionNote() {
      return !!this.releases.find(
        (release) => release.name.replace('v', '') === this.releaseVersion,
      );
    },

    sourceBranches() {
      const versions = this.releases.map((release) => release.tag_name.replace('v', ''));
      return this.branches.filter(
        (branch) =>
          branch.name.includes('release') &&
          !versions.includes(branch.name.replace('release/', '')),
      );
    },

    targetBranches() {
      return this.branches.filter((branch) => branch.name.includes('master'));
    },

    jiraIssuesWithoutMergeRequest() {
      return this.jiraIssues.filter(
        ({ key, type }) =>
          !['task'].includes(type.toLowerCase()) &&
          !this.mergeRequests.find((mergeRequest) => !!mergeRequest.title?.includes(key)),
      );
    },
  },

  methods: {
    async selectProject(project) {
      this.loading = true;
      this.currentProject = project;
      this.releases = [];
      this.branches = [];
      this.newRelease = null;

      this.releases = await GitlabApi.fetchReleases(this.currentProjectId).catch(this.handlerError);
      this.branches =
        (await GitlabApi.fetchBranches(this.currentProjectId).catch(this.handlerError)) || [];
      if (this.sourceBranches[0]?.name) {
        await this.selectSourceBranch(this.sourceBranches[0].name);
      }
      this.loading = false;
    },

    async selectSourceBranch(branchName) {
      this.loading = true;
      this.masterVersion = '';
      this.releaseVersion = '';
      this.mergeRequests = [];
      this.newRelease = null;
      this.sourceBranch = this.sourceBranches.find((branch) => branch.name === branchName);
      this.targetBranch = this.targetBranches.find((branch) => branch.name === 'master');
      this.mergeRequests = await GitlabApi.fetchMergeRequests(
        this.currentProjectId,
        this.sourceBranch.name,
      ).catch(this.handlerError);
      this.releaseVersion = await GitlabApi.fetchVersion(
        this.currentProjectId,
        this.sourceBranch.name,
      ).catch(this.handlerError);
      this.masterVersion = await GitlabApi.fetchVersion(
        this.currentProjectId,
        this.targetBranch.name,
      ).catch(this.handlerError);
      this.jiraIssues = await this.getJiraIssues();
      await this.createReleasePreview();
      this.loading = false;
    },

    async selectTargetBranch(branchName) {
      this.loading = true;
      this.targetBranch = this.targetBranches.find((branch) => branch.name === branchName);
      this.masterVersion = await GitlabApi.fetchVersion(
        this.currentProjectId,
        this.targetBranch.name,
      ).catch(this.handlerError);
      await this.createReleasePreview();
      this.loading = false;
    },

    async createReleasePreview() {
      const mergeRequestsByType = {
        Epic: [],
        Story: [],
        Feature: [],
        Bug: [],
        Refactoring: [],
        Rest: [],
      };

      this.mergeRequests.forEach((mergeRequest) => {
        const type =
          this.jiraIssues.find((issue) => mergeRequest.title.includes(issue.key))?.type || 'Rest';
        mergeRequestsByType[type] = mergeRequestsByType[type] || [];
        mergeRequestsByType[type].push(mergeRequest.title);
      });

      const description = Object.entries(mergeRequestsByType).reduce((result, [type, issues]) => {
        if (issues.length) {
          result += `**${type}**\n${issues.map((issue) => `- ${issue}`).join('\n')}\n\n`;
        }
        return result;
      }, '');

      const descriptionFormatted = Object.entries(mergeRequestsByType).reduce(
        (result, [type, issues]) => {
          if (issues.length) {
            result += `<h5>${type}</h5><div class="mb-3">${issues
              .map((issue) => `- ${this.formatMergeRequestTitle(issue)}`)
              .join('<br />')}</div>`;
          }
          return result;
        },
        '',
      );

      this.newRelease = {
        title: `Release v${this.releaseVersion}`,
        description,
        descriptionFormatted,
        mergeRequest: null,
      };
    },

    formatMergeRequestTitle(title) {
      return title.replace(
        /(SEQ-\d+)/,
        '<a href="https://lifestream.atlassian.net/browse/$1" target="_blank">$1</a>',
      );
    },

    async submitMergeRequest() {
      this.loading = true;
      this.newRelease.mergeRequest = await GitlabApi.createMergeRequest(this.currentProjectId, {
        source_branch: this.sourceBranch.name,
        target_branch: this.targetBranch.name,
        title: this.newRelease.title,
        description: this.newRelease.description,
        labels: ['Release'],
      }).catch(this.handlerError);
      this.loading = false;
    },

    async getJiraIssues() {
      const currentProjectName = this.currentProject.name.replace('sequoia-', '');
      if (currentProjectName && this.releaseVersion) {
        const response = await JiraApi.getReleaseIssues(
          currentProjectName,
          this.releaseVersion,
        ).catch((e) => {
          if (e.status === 400) {
            // eslint-disable-next-line no-console
            console.error(e);
            return null;
          }
          throw e;
        });
        if (response?.total > 0) {
          return (
            response.issues?.map((issue) => {
              return {
                key: issue.key,
                summary: issue.fields.summary,
                type: issue.fields.issuetype.name,
              };
            }) || []
          );
        }
      }
      return [];
    },

    handlerError(e) {
      if (e instanceof ApiError) {
        if (!e.status) {
          this.noConnection = true;
        } else {
          this.error = `${e.method} ${e.url} - ${e.message}`;
        }
      } else {
        this.error = e.message;
      }
    },
  },
};
</script>
