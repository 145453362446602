<template>
  <table id="instances-table">
    <tr>
      <th />
      <th
        v-for="(env, key) in headings"
        class="status-header text-center"
        :class="key"
        :colspan="env.hosts.length"
      >
        {{ env.name }}
      </th>
    </tr>
    <tr>
      <th class="wl status-header">Whitelabel</th>
      <template v-for="(heading, key) in headings">
        <th v-for="url in heading.hosts" class="status-header text-center" :class="key">
          {{ url }}
        </th>
      </template>
    </tr>
    <tr v-for="(wl, wlName) in $store.state.wlInstancesStatuses" class="wl-row">
      <td class="wl-name">{{ wlName }}</td>
      <td v-for="key in instancesURLS" class="text-center">
        <InstancesTableStatus :instanceData="wl[key]" />
      </td>
    </tr>
  </table>
</template>

<script>
import { instancesURLS } from '../pages/Whitelabels';
import InstancesTableStatus from './InstancesTableStatus';

export default {
  components: { InstancesTableStatus },
  computed: {
    instancesURLS: () => instancesURLS.filter((url) => !['urlFeProd', 'urlFeRC'].includes(url)),
  },
  data() {
    return {
      headings: {
        prod: {
          name: 'PROD',
          hosts: ['EXTERNAL', 'SITE', 'ADMIN', 'API'],
        },
        'rc-prod': {
          name: 'RC → API PROD',
          hosts: ['SITE', 'ADMIN'],
        },
        rc: {
          name: 'RC',
          hosts: ['SITE', 'ADMIN', 'API'],
        },
      },
    };
  },
};
</script>

<style lang="scss">
#instances-table {
  width: 100%;
  min-height: 2rem;

  .wl-row:nth-child(3) td {
    padding-top: 4px;
  }

  td {
    padding: 2px 0;
  }
}
.status-header.text-center {
  text-align: center;
}
.status-header.prod {
  background: #fae1f1;
}
.status-header.rc-prod {
  background: #fff7cc;
}
.status-header.rc {
  background: #edffcc;
}
.status-header.fe {
  background: #d3f2fd;
}
.status-header.wl {
  text-align: left;
}
.wl-row:hover {
  background: rgba(250, 237, 225, 0.3);
}
</style>
