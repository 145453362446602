import Vue from 'vue';
import Vuex from 'vuex';

// noinspection JSFileReferences
import * as importedActions from './actions/*.js';

import { consoleMessagesLimit } from './config/constants';

Vue.use(Vuex);

let userInStorage = localStorage.getItem('user');

try {
  userInStorage = JSON.parse(userInStorage);
} catch (e) {
  userInStorage = null;
}

const requests = [
  {
    name: 'auth',
    customFunctions: {
      success: (state, { user, token }) => {
        state.token = token;
        state.user = user;
      },
    },
  },
  { name: 'reCheckInstances' },

  // { name: 'addCombination' },
  // { name: 'editCombination' },
  // { name: 'deleteCombination' },

  { name: 'addWhitelabel' },
  { name: 'editWhitelabel' },
  { name: 'deleteWhitelabel' },

  { name: 'addStaging' },
  { name: 'editStaging' },
  { name: 'deleteStaging' },

  // { name: 'addPlatform' },
  // { name: 'editPlatform' },
  // { name: 'deletePlatform' },
  //
  // { name: 'editInstallation' },
  // { name: 'deleteInstallation' },

  // { name: 'deleteBuild' },
  // { name: 'deleteAllBuilds' }
];

const getMutationsForRequests = (requestsNames) =>
  requestsNames
    .map(({ name, customFunctions = {} }) => ({
      [`${name}RequestPending`]: (state, ...params) => {
        state.requests[name].status = 'pending';
        if (typeof customFunctions.pending === 'function')
          customFunctions.pending(state, ...params);
      },
      [`${name}RequestSuccess`]: (state, ...params) => {
        state.requests[name].status = 'success';
        Vue.notify({
          group: 'main',
          title: `${name} request status`,
          text: 'Success',
          type: 'success',
        });
        if (typeof customFunctions.success === 'function')
          customFunctions.success(state, ...params);
      },
      [`${name}RequestError`]: (state, ...params) => {
        state.requests[name].status = 'error';
        state.requests[name].errorText = params[0]?.response?.data?.message || 'Network Error';
        Vue.notify({
          group: 'main',
          title: `${name} request status`,
          text: state.requests[name].errorText,
          type: 'error',
        });
        if (typeof customFunctions.error === 'function') customFunctions.error(state, ...params);
      },
    }))
    .reduce((acc, current) => ({ ...acc, ...current }), {});

const getStateForRequests = (requestsNames) =>
  requestsNames
    .map(({ name }) => ({
      [`${name}`]: { status: '', errorText: '' },
    }))
    .reduce((acc, current) => ({ ...acc, ...current }), {});

const store = new Vuex.Store({
  state: {
    requests: {
      ...getStateForRequests(requests),
    },
    token: localStorage.getItem('token') || '',
    user: userInStorage || {},
    ws: {
      console: [],
    },
    wlInstancesStatuses: [],
    whitelabels: [],
    users: [],

    instancesStatusUpdateTime: 0,
    instancesStatusUpdateInterval: 0,
  },
  mutations: {
    ...getMutationsForRequests(requests),
    logout(state) {
      state.token = '';
      state.user = {};
    },
    insertWSconsoleMessages(state, data) {
      state.ws.console.push(data.data);
      if (state.ws.console.length > consoleMessagesLimit) {
        state.ws.console.shift();
      }
    },
    insertWSwlInstancesStatusesMessages(state, data) {
      state.wlInstancesStatuses = data.data || {};
    },
    insertWSwlInstancesConfigMessages(state, data) {
      state.instancesStatusUpdateTime = data.data ? data.data.value || 0 : 0;
    },

    insertWhitelabelInstancesStatusesMessages(state, data) {
      state.wlInstancesStatuses = data.data || {};
    },

    insertWhitelabelInstancesStatusesConfig(state, data) {
      state.instancesStatusUpdateTime = data.data.instancesStatusUpdateTime || 0;
      state.instancesStatusUpdateInterval = data.data.instancesStatusUpdateInterval || 0;
    },

    insertWhitelabels(state, data) {
      state.whitelabels = data.data || {};
    },

    insertUsers(state, data) {
      state.users = data.data || {};
    },

    resetWLInstancesStatusUpdateTime(state) {
      state.instancesStatusUpdateTime = 0;
      state.instancesStatusUpdateInterval = 0;
    },
  },
  actions: Object.values(importedActions).reduce((acc, obj) => ({ ...acc, ...obj }), {}),
  getters: {
    isLoggedIn: (state) => !!state.token,
    isRequestPending: (state) => (requestType) => state.requests[requestType].status === 'pending',
  },
});

export default store;
