import LocalApi from '@/helpers/localApi';
import { STAGING_STATUS } from '@/config/constants';

const types = ['admin', 'api', 'ssr'];

export const getStagings = async () => {
  const responses = await Promise.all(
    types.map((type) =>
      new LocalApi().setEndpoint('/v1/admin/staging/get').setParams({ type }).get(),
    ),
  );
  return responses.reduce((acc, { available, stagings }, index) => {
    acc[types[index]] = {
      items: stagings.map((staging) => ({ ...staging, status: STAGING_STATUS.UNKNOWN })),
      available,
    };
    return acc;
  }, {});
};

export const getStagingsStatus = async () => {
  return await Promise.all(
    types.map((type) =>
      new LocalApi().setEndpoint('/v1/admin/staging/check-outdated').setParams({ type }).get(),
    ),
  );
};

export const postStagingAdd = (body) =>
  new LocalApi().setEndpoint('/v1/admin/staging/add').setBody(body).post();

export const postStagingUpdate = (body) =>
  new LocalApi().setEndpoint('/v1/admin/staging/update').setBody(body).post();

export const postStagingRefresh = ({ type, name, feBaseUrl, branch }) =>
  new LocalApi()
    .setEndpoint('/v1/admin/staging/refresh')
    .setBody({ type, name, feBaseUrl, branch })
    .post();

export const postStagingDelete = ({ type, name }) =>
  new LocalApi().setEndpoint('/v1/admin/staging/destroy').setBody({ type, name }).post();
