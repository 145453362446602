import Vue from 'vue';
import Router from 'vue-router';
import * as views from './pages/*.vue';

import store from './store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: views.Dashboard,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/whitelabels',
      name: 'Whitelabels',
      component: views.Whitelabels,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/users',
      name: 'Users',
      component: views.Users,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: views.Login,
      meta: {
        requiresNotAuth: true,
      },
    },
    {
      path: '/stagings',
      name: 'Stagings',
      component: views.Stagings,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/releases',
      name: 'Releases',
      component: views.Releases,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next('/login');
  } else if (to.matched.some((record) => record.meta.requiresNotAuth)) {
    if (store.getters.isLoggedIn) {
      next('/');
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
